export const textDefaultValues = {
  text1: "Have a Good Day!",
  text2: "YOUR NAME",
};

export const emptyPlaceholder = {
  halfWidth: "---",
};

export const allPlaceholder = {
  halfWidth: "ALL",
};

export const labelFontSize = {
  A: {
    text1: 31,
    text2: 67,
    time: 34,
    shop: 15,
    drinkOption: 29,
    filter: 20,
    orderId: 14,
  },
  B: {
    text2: 67,
    text1: 34,
    time: 24,
    drinkOption: 24,
    filter: 19,
    shop: 15,
    orderId: 17,
  },
};

export const kernJSON = {
  A: {
    text1: "kern",
    text2: "kern",
    time: "kern12",
    shop: "kern10",
    drinkOption: "kern5",
    filter: "kern10",
    orderId: "kern15",
  },
  B: {
    text1: "kern",
    text2: "kern",
    time: "kern12",
    shop: "kern10",
    drinkOption: "kern8",
    filter: "kern10",
    orderId: "kern15",
  },
};

export const textMaxLength = 290;

export const labelTemplateName = {
  A: "S",
  B: "W",
};

export const bannerPageSize = 25;
