import React, { useMemo } from "react";

import "./index.scss";

const OrderDetailComponent = ({ orderDetails, totalPrice }) => {
  const priceFormat = (price) => {
    return "¥" + price;
  };

  return useMemo(
    () => (
      <div className="order__container">
        <div className="order-detail__container">
          <div className="order-detail__row">
            <span className="order-detail__label">CATEGORY</span>
            <span className="order-detail__name">
              {orderDetails.drink_category_name}
            </span>
            <span className="order-detail__price">
              {priceFormat(orderDetails.drink_category_price || 0)}
            </span>
          </div>
          <div className="order-detail__row">
            <span className="order-detail__label">DRINK</span>
            <span className="order-detail__name">
              {orderDetails.drink_name.replace(/\n/g, '')}
            </span>
            <span className="order-detail__price">
              {priceFormat(orderDetails.drink_price)}
            </span>
          </div>
          {orderDetails.options &&
            orderDetails.options.map((item, index) => (
              <div className="order-detail__row" key={index}>
                <span className="order-detail__label">
                  {item.customize_name}
                </span>
                <span className="order-detail__name">{item.option_name}</span>
                <span className="order-detail__price">
                  {priceFormat(item.option_price)}
                </span>
              </div>
            ))}
          <div className="order-detail__row">
            <span className="order-detail__label">LABEL</span>
            <span className="order-detail__name">
              {orderDetails.design_template_type}
            </span>
            <span className="order-detail__price">
              {priceFormat(orderDetails.design_template_price)}
            </span>
          </div>
          <div className="order-detail__row">
            <span className="order-detail__label">BANNER</span>
            <span className="order-detail__name">
              {orderDetails.banner_type}
            </span>
            <span className="order-detail__price">
              {priceFormat(orderDetails.banner_price)}
            </span>
          </div>
        </div>
        <div className="total-price__container">
          <span className="total-price__label">合計価格</span>
          <span className="total-price__value">¥{totalPrice}</span>
        </div>
      </div>
    ),
    [orderDetails]
  );
};

export default OrderDetailComponent;
