import querystring from "query-string";
import { useLocation } from "@reach/router";

export const useParseUrl = (
  url: string,
  additionQueryParams = {},
  excludesParams = []
) => {
  const { search } = useLocation();
  const initialExcludeParams = ["manualOrder"];
  const { parse, stringify } = querystring;
  const parsedSearch = parse(search);
  [...initialExcludeParams, ...excludesParams].forEach(
    (param) => delete parsedSearch[param]
  );
  url +=
    Object.keys(parsedSearch).length || Object.keys(additionQueryParams).length
      ? `?${stringify({
          ...parsedSearch,
          ...additionQueryParams,
        })}`
      : "";
  return url;
};
