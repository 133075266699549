import React from "react"
import Layout from '../components/Layout'
import { Redirect, Router } from "@reach/router"
import PrivateRoute from '../components/PrivateRoute/index';
import AcceptOrder from '../components/AcceptOrder/index';
import OrderError from '../components/OrderError/index';
import CompleteOrder from '../components/CompleteOrder/index';
import Login from "../components/Login/index";
import NotFoundPage from "../components/404/index";
import UpdatePassword from "../components/UpdatePassword/index";
import ManualOrder from "../components/ManualOrder/index";
import loadable from '@loadable/component';
import { Provider } from "react-redux";

import { store } from "../models/store";

const QRReader = loadable(() => import('../components/QRCodeReader'), { ssr: false });

const IndexPage = (props) => {
  return (
    <Provider store={store}>
      <Layout >
        <Router>
          <Login path="/login" />
          <NotFoundPage path="/404" />
          <PrivateRoute path="/accept-order" component={AcceptOrder} />
          <PrivateRoute path="/accept-order-error" component={OrderError} />
          <PrivateRoute path="/complete-order" component={CompleteOrder} />
          <PrivateRoute path="/read-qrcode" component={QRReader} />
          <PrivateRoute path="/update-password" component={UpdatePassword} />
          <PrivateRoute path="/manual-order" component={ManualOrder} />
          <Redirect from="/" to="/read-qrcode" noThrow />
          <Redirect from="*" to="/404" noThrow />
        </Router>
      </Layout>
    </Provider>
  )
}
export default IndexPage;
