import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";

import AcceptOrderHeader from "../AcceptOrder/AcceptOrderHeader";
import OrderDetailCompleteComponent from "./orderDetailComplete";
import BackToQRreaderComponent from "./BackToQRreader";
import UserDropDown from "../UserDropDown";
import { RootState } from "../../models/store";
import { useParseUrl } from "../../hooks/useParseUrl";

import "./index.scss";

const CompleteOrder = () => {
  const manualOrder = new URLSearchParams(location.search).get("manualOrder");
  const isWebview = new URLSearchParams(location.search).get("webview");
  const completeOrderBackUrl = useParseUrl(
    manualOrder && isWebview ? "/manual-order" : "/"
  );
  const orderDetails = useSelector((state: RootState) => state.qrCode.data);
  const orderID = useSelector((state: RootState) => state.qrCode.orderID);
  const totalPrice = useSelector((state: RootState) => state.qrCode.totalPrice);

  useEffect(() => {
    if (!orderDetails) {
      navigate(completeOrderBackUrl);
    }
  });

  return useMemo(
    () =>
      orderDetails ? (
        <>
          <UserDropDown excludeTestModeStyle />
          <AcceptOrderHeader excludeTestModeStyle title="注文受付完了" />
          <div className="complete-icon">
            <img className="complete-icon__image" src="/success.png" />
            <div className="complete-icon__wrapper">
              <span className="complete-icon__label">注文受付番号</span>
              <span className="complete-icon__number">{orderID}</span>
            </div>
          </div>
          <OrderDetailCompleteComponent
            orderDetails={orderDetails}
            totalPrice={totalPrice}
          />
          <div
            className={`complete-back-button ${
              isWebview ? "complete-back-button--webview" : ""
            }`}
          >
            <BackToQRreaderComponent />
          </div>
        </>
      ) : null,
    [orderDetails, isWebview]
  );
};

export default CompleteOrder;
