import axios from 'axios';
import { endpoint } from './api-endpoint';
import './api-interceptors';
import { error_msg } from '../../const/ja';
const ENDPOINT = endpoint;
class ApiService {
  /**
   * GET method
   * @param {*} uri : endpoint of api - String or Object EX: {endpoint: '/users/:id', id: '123'}
   */
  get(uri) {
    const { url } = this._infoHandler(uri);
    return axios({
      method: 'GET',
      url: url,
    }).catch((e) => {
      this._errorHandle(e)
    });
  }
  /**
   * POST method
   * @param {*} uri : endpoint of api - String or Object EX: {endpoint: '/users/:id', id: '123'}
   * @param {*} data : data send to server {}
   */
  post(uri = '', data = {}) {
    const { url } = this._infoHandler(uri);
    return axios({
      method: 'POST',
      url: url,
      data: data
    }).catch((e) => {
      this._errorHandle(e)
    });
  }
  /**
   * PUT method
   * @param {*} uri : endpoint of api - String or Object EX: {endpoint: '/users/:id', id: '123'}
   * @param {*} data : data send to server {}
   */
  put(uri = '', data = {}) {
    const { url } = this._infoHandler(uri);
    return axios({
      method: 'PUT',
      url: url,
      data: data
    }).catch((e) => {
      this._errorHandle(e)
    });
  }
  /**
   * PATH method
   * @param {*} uri : endpoint of api - String or Object EX: {endpoint: '/users/:id', id: '123'}
   * @param {*} data : data send to server {}
   */
  path(uri = '', data = {}) {
    const { url } = this._infoHandler(uri);
    return axios({
      method: 'PATH',
      url: url,
      data: data
    }).catch((e) => {
      this._errorHandle(e)
    });
  }
  _infoHandler(uri) {
    let url = uri;
    if (typeof uri === 'object') {
      Object.keys(uri).forEach((e) => {
        url = uri.endpoint.replace(`:${e}`, uri[e]);
      });
    }
    return { url };
  }
  _errorHandle(error) {
    if (error.response) {
      console.log(error.response.status);
      console.log(error.response.headers);
      console.log(error.data);
      console.log(error.request);
    } else {
      console.log(error.message);
    }
    throw error;
  };
  handleErrorMess(error) {
    if (error.response) {
      return error.response.data.message || 504
    } else {
      const isOnline = window.navigator.onLine
      let status = error.code === 'ECONNABORTED' || !isOnline ? 504 : 403;
      return error_msg[status];
    }
  };
}

const apiService = new ApiService();
export {
  apiService,
  ENDPOINT
}
