export const error_msg = {
  403: "アクセスできません",
  504: "サーバー側に接続できません。通信環境を確認の上、再度お試しください。",
  wrong_input: "半角英数字と半角記号8文字以上32文字以内で入力して下さい。",
  login: "ログインに失敗しました",
  update_password: "パスワード変更に失敗しました",
  different_password:
    "新しいパスワードと再入力パスワードが一致しません。再度ご確認ください。",
  qr_wrong_format:
    "QRコードが不正なフォーマットです。\nラベルを再度作成してください。",
  get_manual_order_metadata:
    "注文処理中にエラーが発生しました。\nお手数ですが、注文アプリからご注文ください。",
};
export const success_msg = {
  update_password: "パスワード変更しました。再度ログインしてください。",
};
