import React, { useEffect, useRef, useState } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import querystring from "query-string";

import { RootState } from "../../models/store";
import { useParseUrl } from "../../hooks/useParseUrl";

import "./index.scss";

const UserDropDown = ({ excludeTestModeStyle = false }) => {
  const updatePasswordUrl = useParseUrl("/update-password");
  const { search } = useLocation();
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch<any>();
  const info = useSelector((state: RootState) => state.auth.info);
  const isWebview = useSelector((state: RootState) => state.qrCode.isWebview);
  const isInTestMode = useSelector((state: RootState) => !!state.top.testmode);
  const dropMenu = useRef(null);
  const parsedQuery = querystring.parse(search, { parseBooleans: true });

  const handleLogout = () => {
    dispatch.auth.logout();
  };

  const handleToggle = () => {
    setIsShow(!isShow);
  };

  useEffect(() => {
    if (!isWebview) {
      dispatch.qrCode.setIsWebview({
        isWebview: !!parsedQuery.webview,
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", (event) => {
      if (dropMenu && !dropMenu.current?.contains(event.target)) {
        setIsShow(false);
      }
    });
  }, []);

  return isWebview ? null : (
    <div ref={dropMenu} className="dropdown-user">
      <div className="dropdown-icon" onClick={handleToggle}>
        <img
          src={
            isInTestMode && !excludeTestModeStyle
              ? "/user_white.png"
              : "/user_black.png"
          }
          alt="user"
        />
      </div>
      <ul className={`dropdown-menu ${isShow ? "show" : ""}`}>
        <li className="dropdown-item text-center">{info.login_id}</li>
        <li
          className="dropdown-item"
          onClick={() => navigate(updatePasswordUrl)}
        >
          パスワード変更
        </li>
        <li className="dropdown-item" onClick={handleLogout}>
          ログアウト
        </li>
      </ul>
    </div>
  );
};

export default UserDropDown;
