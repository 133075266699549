import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";

import { useParseUrl } from "../../hooks/useParseUrl";
import { RootState } from "../../models/store";
import BackToQRreaderComponent from "../CompleteOrder/BackToQRreader";

import "./index.scss";

const OrderError = () => {
  // エラーメッセージがどんな形式で送られてくるかは要検討
  const manualOrder = new URLSearchParams(location.search).get("manualOrder");
  const isWebview = new URLSearchParams(location.search).get("webview");
  const topUrl = useParseUrl(manualOrder && isWebview ? "/manual-order" : "/");
  const error = useSelector((state: RootState) => state.qrCode.error);

  useEffect(() => {
    if (!error) {
      navigate(topUrl);
    }
  }, [error]);

  return useMemo(
    () =>
      error ? (
        <div className="error-wrapper">
          <div className="error-message-area">
            <img
              width={70}
              src="/icon-NG@3x.png"
              className="error-icon"
              alt="error"
            />
            <span className="error-title">エラーが発生しました</span>
            <span className="error-message">{error?.message}</span>
          </div>
          <div
            className={`error-back-button-wrapper ${
              isWebview ? "error-back-button-wrapper--webview" : ""
            }`}
          >
            <BackToQRreaderComponent authError={error?.status === 401} />
          </div>
        </div>
      ) : null,
    [error, isWebview]
  );
};

export default OrderError;
