import fontLib from "../fonts/_font.json";
import offsetLib from "../fonts/_offset.json";
import kern from "../fonts/_kern.json";
import kern5 from "../fonts/_kern+5.json";
import kern8 from "../fonts/_kern+8.json";
import kern10 from "../fonts/_kern+10.json";
import kern12 from "../fonts/_kern+12.json";
import kern15 from "../fonts/_kern+15.json";
import {
  kernJSON,
  labelFontSize,
  textDefaultValues,
  textMaxLength,
} from "../core/const/global";
import { convertToHalfWidth } from "./stringHelper";

const setTextTagFont = (name, value, labelType) => {
  const inputTagFont = tagFontHelper(
    value || textDefaultValues[name],
    labelFontSize?.[labelType]?.[name],
    kernJSON?.[labelType]?.[name]
  );
  const invalidLetters =
    inputTagFont?.content?.filter((item) => item?.invalid) || [];
  if (invalidLetters.length > 0) {
    return "英数字や記号を入力してください";
  } else if (inputTagFont?.maxLength > textMaxLength) {
    return "文字数オーバーです";
  } else {
    return true;
  }
};

const getPosition = (targets, kernJSON) => {
  let prev = "";
  let step = [];
  let offset = [];
  step[0] = 0;
  for (let i = 0; i < targets.length; i++) {
    let next = targets[i];
    if (/^[0-9]+$/i.test(next)) {
      offset[i] = offsetLib[next];
      step[i] -= offset[i];
    } else {
      offset[i] = 0;
    }
    if (prev !== "") {
      let move =
        kernJSON === "kern"
          ? kern[prev + next]
          : kernJSON === "kern5"
          ? kern5[prev + next]
          : kernJSON === "kern8"
          ? kern8[prev + next]
          : kernJSON === "kern10"
          ? kern10[prev + next]
          : kernJSON === "kern12"
          ? kern12[prev + next]
          : kern15[prev + next];
      step[i] = move;
    }
    prev = next;
  }
  return { step, offset };
};

const tagFontHelper = (string, fontSize, kernJSON) => {
  let tagFont = {
    content: [],
    maxLength: 0,
  };
  if (string) {
    const array = convertToHalfWidth(string).split("");
    const position = getPosition(string, kernJSON);
    const K = (fontSize / 841) * fontLib["A"].scale;
    let result = [];
    let positionX = 0;
    let maxLength = 0;
    array.map((item, i) => {
      positionX += position.step[i] * K;
      let positionLeft: number = positionX + position.offset[i] * K;

      if (fontLib[item]) {
        result.push({
          image: `/char-images/${fontLib[item].code}.svg`,
          name: item,
          style: {
            position: "absolute",
            top: 0,
            left: `${positionLeft.toFixed(2)}px`,
            height: `${fontSize}px`,
          },
        });
        maxLength = Math.ceil(
          positionLeft + (fontLib[item].width * fontSize) / 841
        );
      } else {
        result.push({ invalid: item });
      }
    });
    tagFont = {
      content: result,
      maxLength,
    };
  } else {
    tagFont = {
      content: [],
      maxLength: 0,
    };
  }
  return tagFont;
};

export default setTextTagFont;
