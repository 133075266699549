import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../models/store";

import "./index.scss";

const AcceptOrderHeaderComponent = ({
  title = "",
  excludeTestModeStyle = false,
}) => {
  const isInTestMode = useSelector((state: RootState) => !!state.top.testmode);

  return (
    <div className="accept-order-header">
      <p
        className={`title ${
          isInTestMode && !excludeTestModeStyle ? "title--test-mode" : ""
        }`.trim()}
      >
        {title}
      </p>
    </div>
  );
};

export default AcceptOrderHeaderComponent;
