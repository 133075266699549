export const handleDisabled = (
  formValue: object,
  invalid: object,
  isLoading: boolean = false,
  fieldLength: number
): boolean => {
  return (
    Object.values(invalid).find((x) => x) ||
    Object.values(formValue).findIndex((x) => x?.trim().length < 8) > -1 ||
    Object.values(formValue)?.length < fieldLength ||
    isLoading
  );
};
