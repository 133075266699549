import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { navigate } from "gatsby";
import { useInView } from "react-intersection-observer";
import camelcaseKeys from "camelcase-keys";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { captureException } from "@sentry/gatsby";

import "./index.scss";
import { RootState } from "../../models/store";
import AcceptOrderHeader from "../AcceptOrder/AcceptOrderHeader";
import UserDropDown from "../UserDropDown";
import { apiService } from "../../core/services/api/api-service";
import { endpoint } from "../../core/services/api/api-endpoint";
import { chunk } from "../../utils/arrayHelper";
import {
  filterDataByCondition,
  sortByPriority,
} from "../../utils/bannerFilterHelper";
import {
  textDefaultValues,
  allPlaceholder,
  bannerPageSize,
  emptyPlaceholder,
} from "../../core/const/global";
import { error_msg } from "../../core/const/ja";
import { convertToHalfWidth, formatCharacter } from "../../utils/stringHelper";
import setTextTagFont from "../../utils/tagFontHelper";
import { useParseUrl } from "../../hooks/useParseUrl";
import ModeDropDown from "../ModeDropDown";

const ManualOrder = () => {
  const {
    register,
    watch,
    handleSubmit,
    trigger,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
  });
  const completeOrderBackUrl = useParseUrl("/complete-order", {
    manualOrder: true,
  });
  const acceptOrderErrorBackUrl = useParseUrl("/accept-order-error", {
    manualOrder: true,
  });
  const acceptOrderErrorUrl = useParseUrl("/accept-order-error");
  const topUrl = useParseUrl("/");
  const dispatch = useDispatch();
  const isInTestMode = useSelector((state: RootState) => !!state.top.testmode);
  const isWebview = useSelector((state: RootState) => state.qrCode.isWebview);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [drinks, setDrinks] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [filterData, setFilterData] = useState({} as any);
  const [bannerDisplayDataFiltered, setBannerDisplayDataFiltered] = useState({
    filteredData: [],
    loadedPageNumber: 1,
    displayData: [],
  });
  const [bannerFiltered, setBannerFiltered] = useState({
    categoryType: null,
    color: null,
  });
  const [loadingRef, inView] = useInView({
    threshold: 0,
  });
  const ref = useRef(null);
  const categoryId = watch("categoryId");
  const drinkId = watch("drinkId");
  const hotColdId = watch("hotCold");
  const espressoId = watch("espresso");
  const sweetId = watch("sweet");
  const optionId = watch("option");
  const bannerId = watch("bannerId");
  const labelId = watch("labelId");
  const text1 = watch("text1");
  const text2 = watch("text2");
  const bannerPrice = watch("bannerPrice");

  const drinkCustomizeOptions = useCallback(
    (optionKey) => {
      const drinkCustomizes =
        drinks?.find((item) => item.id === +drinkId)?.drink_customizes || [];
      return (
        drinkCustomizes?.find((customize) => customize.value === optionKey)
          ?.drink_customize_options || []
      );
    },
    [drinkId, drinks]
  );

  const espressoBerryOption = drinkCustomizeOptions("espresso")?.find(
    (item) => item.option_name === "ストロベリー"
  );
  const isSelectBerryOption = espressoBerryOption?.id === +espressoId;
  const sweetOptions = drinkCustomizeOptions("sweet")?.filter(
    (option) => !isSelectBerryOption || option?.option_name === "甘め"
  );

  const currentLabel = useMemo(
    () => templates?.find((template) => template?.id === +labelId),
    [templates, labelId]
  );

  const totalPrice = useMemo(() => {
    const categoryPrice =
      categories?.find((item) => item.id === +categoryId)?.price || 0;
    const drinkPrice = drinks?.find((item) => item.id === +drinkId)?.price || 0;
    const hotColdPrice =
      drinkCustomizeOptions("hotCold")?.find((item) => item.id === +hotColdId)
        ?.option_price || 0;
    const espressoPrice =
      drinkCustomizeOptions("espresso")?.find((item) => item.id === +espressoId)
        ?.option_price || 0;
    const sweetPrice =
      sweetOptions?.find((item) => item.id === +sweetId)?.option_price || 0;
    const optionPrice =
      drinkCustomizeOptions("option")?.find((item) => item.id === +optionId)
        ?.option_price || 0;
    const labelPrice = currentLabel?.design_template_price || 0;
    return (
      categoryPrice +
      drinkPrice +
      hotColdPrice +
      espressoPrice +
      sweetPrice +
      optionPrice +
      labelPrice +
      (bannerPrice || 0)
    );
  }, [
    categoryId,
    drinkId,
    hotColdId,
    espressoId,
    sweetId,
    optionId,
    currentLabel,
    bannerPrice,
  ]);

  const category = categories?.find((item) => item.id === +categoryId);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const filterLabels = [];
    Object.keys(bannerFiltered).map((key) => {
      if (bannerFiltered[key]?.id || bannerFiltered[key].name === "COLOR") {
        filterLabels.push(bannerFiltered[key].name);
      } else {
        filterLabels.push(allPlaceholder.halfWidth);
      }
    });
    filterLabels.splice(1, 0, emptyPlaceholder.halfWidth);
    const optionIds = [];
    if (data.hotCold) {
      optionIds.push(+data.hotCold);
    }
    if (data.espresso) {
      optionIds.push(+data.espresso);
    }
    if (data.option) {
      optionIds.push(+data.option);
    }
    if (data.sweet) {
      optionIds.push(+data.sweet);
    }
    try {
      const requestData = {
        drink_id: +data.drinkId,
        label_text_1: data.text1 || textDefaultValues.text1,
        label_text_2: data.text2 || textDefaultValues.text2,
        banner_id: +data.bannerId,
        design_template_id: +data.labelId,
        filter_labels: filterLabels,
        option_ids: optionIds,
        is_draft: isInTestMode,
      };
      const result: any = await apiService.post(
        endpoint.manualShopOrder,
        requestData
      );
      dispatch.qrCode.setId(result?.data);
      dispatch.qrCode.setQrCode(result?.data);
      navigate(completeOrderBackUrl);
    } catch (e) {
      captureException(new Error(apiService.handleErrorMess(e) || e));
      dispatch.qrCode.setError({
        status: e.response?.status,
        message: apiService.handleErrorMess(e),
      });
      navigate(acceptOrderErrorBackUrl);
    } finally {
      setIsLoading(false);
    }
  };

  const filterBannerDataDisplay = useCallback(
    (bannerFilter, inputBanners = null) => {
      const array = ["categoryType", "color"];
      const newData = filterDataByCondition(
        inputBanners || filterData?.banners,
        bannerFilter,
        array,
        bannerFilter["categoryType"]?.name === "PICKUP"
      );
      const filteredData = newData?.filter((item) => item !== undefined) || [];
      bannerDisplayDataFiltered.filteredData = chunk(filteredData, 4);
      bannerDisplayDataFiltered.loadedPageNumber = 1;
      bannerDisplayDataFiltered.displayData = chunk(filteredData, 4).slice(
        0,
        bannerPageSize
      );
      setBannerDisplayDataFiltered({ ...bannerDisplayDataFiltered });
    },
    [bannerDisplayDataFiltered, filterData]
  );

  const loadMoreBanners = useCallback(() => {
    if (
      bannerDisplayDataFiltered.displayData.length <
      bannerDisplayDataFiltered.filteredData.length
    ) {
      bannerDisplayDataFiltered.loadedPageNumber += 1;
      bannerDisplayDataFiltered.displayData = [
        ...bannerDisplayDataFiltered.displayData,
        ...bannerDisplayDataFiltered.filteredData.slice(
          (bannerDisplayDataFiltered.loadedPageNumber - 1) * bannerPageSize,
          bannerDisplayDataFiltered.loadedPageNumber * bannerPageSize
        ),
      ];
      setBannerDisplayDataFiltered({ ...bannerDisplayDataFiltered });
    }
  }, [bannerDisplayDataFiltered]);

  const onFilterOptionClick = useCallback(
    (e, key, data) => {
      const filterId = parseInt(e.target.value, 10);
      setBannerFiltered((prev) => {
        const newBannerFiltered = {
          ...prev,
          [key]: {
            id: filterId,
            name:
              data?.filter((item) => item.id + "" === e.target.value)[0]
                ?.filterName || allPlaceholder.halfWidth,
          },
        };
        filterBannerDataDisplay(newBannerFiltered);
        if (ref.current) {
          ref.current.scrollLeft = 0;
        }
        return newBannerFiltered;
      });
    },
    [bannerDisplayDataFiltered, filterData]
  );

  useEffect(() => {
    register("bannerId", { required: true });
    register("bannerPrice");
  }, []);

  useEffect(() => {
    setValue(
      "text1",
      !text1?.trim() ? "" : convertToHalfWidth(formatCharacter(text1)),
      { shouldValidate: true }
    );
  }, [text1]);

  useEffect(() => {
    setValue(
      "text2",
      !text2?.trim() ? "" : convertToHalfWidth(formatCharacter(text2)),
      { shouldValidate: true }
    );
  }, [text2]);

  useEffect(() => {
    setValue("hotCold", drinkCustomizeOptions("hotCold")?.[0]?.id);
    setValue("espresso", drinkCustomizeOptions("espresso")?.[0]?.id);
    setValue("sweet", sweetOptions?.[0]?.id);
    setValue("option", drinkCustomizeOptions("option")?.[0]?.id);
  }, [drinkId]);

  useEffect(() => {
    if (category) {
      setDrinks(category?.drinks || []);
      setValue("drinkId", category?.drinks?.[0]?.id);
    }
  }, [categoryId]);

  useEffect(() => {
    if (currentLabel) {
      trigger();
    }
  }, [currentLabel]);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response: any = await Promise.all([
          apiService.get(endpoint.drinks),
          apiService.get(endpoint.designTemplates),
          apiService.get(endpoint.banners),
        ]);
        const categories = response[0]?.data?.categories;
        const drinks = categories[0].drinks;
        const templates = response[1]?.data?.designs;
        const filterData = camelcaseKeys(response[2]?.data, { deep: true });
        ["categoryTypeFilters", "colorFilters"].forEach((filterKey) => {
          filterData[filterKey] = sortByPriority(filterData[filterKey]);
        });
        const defaultCategoryTypeFilter = filterData?.categoryTypeFilters[0];
        const defaultBannerFiltered = {
          categoryType: {
            id: defaultCategoryTypeFilter.id,
            name: defaultCategoryTypeFilter.filterName,
          },
          color: { id: 0, name: "COLOR" },
        };
        setBannerFiltered(defaultBannerFiltered);
        setCategories(categories);
        setDrinks(drinks);
        setTemplates(templates);
        setFilterData(filterData);
        filterBannerDataDisplay(
          defaultBannerFiltered,
          filterData?.banners || []
        );
        setValue("categoryId", categories?.[0]?.id, {
          shouldValidate: true,
          shouldTouch: true,
        });
        setValue("drinkId", drinks?.[0]?.id, {
          shouldValidate: true,
          shouldTouch: true,
        });
        setValue("labelId", templates?.[0]?.id, {
          shouldValidate: true,
          shouldTouch: true,
        });
      } catch (e) {
        captureException(new Error(apiService.handleErrorMess(e) || e));
        dispatch.qrCode.setError({
          status: e.response?.status,
          message: error_msg["get_manual_order_metadata"],
        });
        navigate(acceptOrderErrorUrl);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (inView) {
      loadMoreBanners();
    }
  }, [inView]);

  return (
    <>
      <UserDropDown />
      <ModeDropDown />
      <AcceptOrderHeader
        title={
          isInTestMode
            ? "マニュアル注文受付（テストモード）"
            : "マニュアル注文受付（営業モード）"
        }
      />

      {isLoading ? (
        <div className="loading-spin">
          <img src="/loading.svg" alt="loading" />
        </div>
      ) : (
        <>
          {isWebview && isInTestMode && (
            <div className="test-mode-note">
              <h3 className="test-mode-title">テストモードとは</h3>
              <p className="test-mode-desc">
                注文受付を行っても売上に反映しない、機器の動作確認用のモードです。詳しくはマニュアルをご覧ください。
              </p>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="manual-order-form">
              <div className="manual-order-form__row">
                <div className="manual-order-form__select">
                  <label className="manual-order-form__label">Category</label>
                  <div
                    className={`manual-order-form__select-wrapper ${
                      categories?.length === 0
                        ? "manual-order-form__select-wrapper--disabled"
                        : ""
                    }`}
                  >
                    <select
                      className="manual-order-form__select-item"
                      {...register("categoryId", {
                        required: true,
                      })}
                    >
                      {categories?.map((category, idx) => (
                        <option key={idx} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <p className="manual-order-form__price">
                  ¥
                  {categories?.find((item) => item.id === +categoryId)?.price ||
                    0}
                </p>
              </div>
              <div className="manual-order-form__row">
                <div className="manual-order-form__select">
                  <label className="manual-order-form__label">Drink</label>
                  <div
                    className={`manual-order-form__select-wrapper ${
                      drinks?.length === 0
                        ? "manual-order-form__select-wrapper--disabled"
                        : ""
                    }`}
                  >
                    <select
                      className="manual-order-form__select-item"
                      {...register("drinkId", {
                        required: true,
                      })}
                    >
                      {drinks?.map((drink, idx) => (
                        <option key={idx} value={drink.id}>
                          {drink.name.replace(/\n/g, '')}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <p className="manual-order-form__price">
                  ¥{drinks?.find((item) => item.id === +drinkId)?.price || 0}
                </p>
              </div>
              <div className="manual-order-form__row">
                <div className="manual-order-form__select">
                  <label className="manual-order-form__label">BASE</label>
                  <div
                    className={`manual-order-form__select-wrapper ${
                      drinkCustomizeOptions("espresso").length === 0
                        ? "manual-order-form__select-wrapper--disabled"
                        : ""
                    }`}
                  >
                    <select
                      className="manual-order-form__select-item"
                      {...register("espresso")}
                    >
                      {drinkCustomizeOptions("espresso")?.map((option, idx) => (
                        <option key={idx} value={option.id}>
                          {option.option_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <p className="manual-order-form__price">
                  ¥
                  {drinkCustomizeOptions("espresso")?.find(
                    (item) => item.id === +espressoId
                  )?.option_price || 0}
                </p>
              </div>
              <div className="manual-order-form__row">
                <div className="manual-order-form__select">
                  <label className="manual-order-form__label">COLD/HOT</label>
                  <div
                    className={`manual-order-form__select-wrapper ${
                      drinkCustomizeOptions("hotCold").length === 0
                        ? "manual-order-form__select-wrapper--disabled"
                        : ""
                    }`}
                  >
                    <select
                      className="manual-order-form__select-item"
                      {...register("hotCold")}
                    >
                      {drinkCustomizeOptions("hotCold")?.map((option, idx) => (
                        <option key={idx} value={option.id}>
                          {option.option_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <p className="manual-order-form__price">
                  ¥
                  {drinkCustomizeOptions("hotCold")?.find(
                    (item) => item.id === +hotColdId
                  )?.option_price || 0}
                </p>
              </div>
              <div className="manual-order-form__row">
                <div className="manual-order-form__select">
                  <label className="manual-order-form__label">SWEET</label>
                  <div
                    className={`manual-order-form__select-wrapper ${
                      sweetOptions?.length === 0
                        ? "manual-order-form__select-wrapper--disabled"
                        : ""
                    }`}
                  >
                    <select
                      className="manual-order-form__select-item"
                      {...register("sweet")}
                    >
                      {sweetOptions?.map((option, idx) => (
                        <option key={idx} value={option.id}>
                          {option.option_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <p className="manual-order-form__price">
                  ¥
                  {sweetOptions?.find((item) => item.id === +sweetId)
                    ?.option_price || 0}
                </p>
              </div>
              <div className="manual-order-form__row">
                <div className="manual-order-form__select">
                  <label className="manual-order-form__label">FLAVOR</label>
                  <div
                    className={`manual-order-form__select-wrapper ${
                      drinkCustomizeOptions("option").length === 0
                        ? "manual-order-form__select-wrapper--disabled"
                        : ""
                    }`}
                  >
                    <select
                      className="manual-order-form__select-item"
                      {...register("option")}
                    >
                      {drinkCustomizeOptions("option")?.map((option, idx) => (
                        <option key={idx} value={option.id}>
                          {option.option_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <p className="manual-order-form__price">
                  ¥
                  {drinkCustomizeOptions("option")?.find(
                    (item) => item.id === +optionId
                  )?.option_price || 0}
                </p>
              </div>
              <div className="manual-order-form__row">
                <div className="manual-order-form__select">
                  <label className="manual-order-form__label">Label</label>
                  <div
                    className={`manual-order-form__select-wrapper ${
                      templates?.length === 0
                        ? "manual-order-form__select-wrapper--disabled"
                        : ""
                    }`}
                  >
                    <select
                      className="manual-order-form__select-item"
                      {...register("labelId")}
                    >
                      {templates?.map((item, idx) => (
                        <option key={idx} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <p className="manual-order-form__price">
                  ¥{currentLabel?.design_template_price || 0}
                </p>
              </div>
              <div className="manual-order-form__row">
                <div className="manual-order-form__select">
                  <label className="manual-order-form__label">Text</label>
                  <input
                    autoComplete="off"
                    type="text"
                    className={`manual-order-form__text ${
                      errors?.text1 ? "manual-order-form__text--error" : ""
                    }`}
                    {...register("text1", {
                      validate: (text1) =>
                        setTextTagFont("text1", text1, currentLabel?.value),
                    })}
                    placeholder={textDefaultValues.text1}
                  />
                </div>
                {errors?.text1 && (
                  <p className="manual-order-form__error">
                    {errors?.text1?.message}
                  </p>
                )}
              </div>
              <div className="manual-order-form__row">
                <div className="manual-order-form__select">
                  <label className="manual-order-form__label">Name</label>
                  <input
                    autoComplete="off"
                    type="text"
                    className={`manual-order-form__text ${
                      errors?.text2 ? "manual-order-form__text--error" : ""
                    }`}
                    {...register("text2", {
                      validate: (text2) =>
                        setTextTagFont("text2", text2, currentLabel?.value),
                    })}
                    placeholder={textDefaultValues.text2}
                  />
                </div>
                {errors?.text2 && (
                  <p className="manual-order-form__error">
                    {errors?.text2?.message}
                  </p>
                )}
              </div>
              <div className="manual-order-form__row">
                <div className="manual-order-form__select">
                  <label className="manual-order-form__label">Banner</label>
                  <div className="manual-order-form__select-group">
                    <div className="manual-order-form__select-wrapper manual-order-form__select-wrapper--item">
                      <select
                        className="manual-order-form__select-item"
                        value={bannerFiltered?.categoryType?.id}
                        onChange={(e) => {
                          onFilterOptionClick(
                            e,
                            "categoryType",
                            filterData["categoryTypeFilters"]
                          );
                          e.target.blur();
                        }}
                      >
                        {filterData?.categoryTypeFilters?.map((option, idx) => (
                          <option key={idx} value={option.id}>
                            {option.filterName}
                          </option>
                        ))}
                        <option value="0">ALL</option>
                      </select>
                    </div>
                    <div className="manual-order-form__select-wrapper manual-order-form__select-wrapper--item">
                      <select
                        className="manual-order-form__select-item"
                        value={bannerFiltered?.color?.id}
                        onChange={(e) => {
                          onFilterOptionClick(
                            e,
                            "color",
                            filterData["colorFilters"]
                          );
                          e.target.blur();
                        }}
                      >
                        <option value="0">COLOR</option>
                        {filterData?.colorFilters?.map((option, idx) => (
                          <option key={idx} value={option.id}>
                            {option.filterName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="manual-order-form__wrapper-banner">
              {bannerDisplayDataFiltered?.filteredData?.length > 0 && (
                <div className="manual-order-form__list-banner" ref={ref}>
                  <div className="manual-order-form__wrapper-item">
                    {bannerDisplayDataFiltered.displayData.map(
                      (item, groupIndex) => (
                        <div
                          className="manual-order-form__item"
                          key={groupIndex}
                        >
                          {item?.map((bannerItem, index) => (
                            <div
                              key={index}
                              className={`manual-order-form__wrapper-image ${
                                bannerItem?.id === bannerId
                                  ? "manual-order-form__wrapper-image--active"
                                  : ""
                              }`}
                              onClick={() => {
                                setValue("bannerId", bannerItem?.id, {
                                  shouldValidate: true,
                                  shouldTouch: true,
                                });
                                setValue(
                                  "bannerPrice",
                                  bannerItem?.bannerPrice
                                );
                              }}
                            >
                              <img
                                className="manual-order-form__image"
                                src={bannerItem.siteImageUrl}
                                alt={bannerItem.image}
                              />
                              {!!bannerItem?.bannerPrice && (
                                <span className="manual-order-form__image--price">
                                  ¥{bannerItem?.bannerPrice}
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      )
                    )}
                    <div
                      className="manual-order-form__loading-more"
                      ref={loadingRef}
                    ></div>
                  </div>
                </div>
              )}
              {!bannerDisplayDataFiltered.displayData?.length && (
                <div className="manual-order-form__no-data">NOT FOUND</div>
              )}
            </div>
            <div className="manual-order-form">
              <div className="manual-order-form__row manual-order-form__row--total">
                <label className="manual-order-form__label manual-order-form__label--sum">
                  合計価格
                </label>
                <p className="manual-order-form__price manual-order-form__price--sum">
                  ¥{totalPrice || 0}
                </p>
              </div>
            </div>
            <div className="btn-group-order">
              <button
                type="button"
                className="btn-accept btn-cancel"
                onClick={() => navigate(topUrl)}
              >
                キャンセル
              </button>
              <button
                type="submit"
                className="btn-accept btn-accept-order"
                disabled={!isValid}
              >
                注文受付
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default ManualOrder;
