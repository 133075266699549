export const regexString = (s: string) => {
  const regex = new RegExp(
    /^[A-Za-z\d`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]{0,32}$/i
  );
  return regex.test(s.trim());
};

export const convertToHalfWidth = (s: string) => {
  return s
    .replace(/[\uff01-\uff5e]/g, (fullWidthChar) =>
      String.fromCharCode(fullWidthChar.charCodeAt(0) - 0xfee0)
    )
    .replace(/\u3000/g, "\u0020")
    .replace(/[ー]/g, "-"); // hyphen filter holder of label
};

export const formatCharacter = (str) => {
  return str
    .replace(/[\“\”]/g, '"')
    .replace(/[\‘\’]/g, "'")
    .replace(/[•]/g, "·");
};
