const url = process.env.GATSBY_API_URL;
const endpoint = {
  shopOrder: 'shop-order',
  preOrder: 'pre-order',
  login: 'login',
  updatePassword: 'update-password',
  drinks: 'drinks',
  designTemplates: 'design-templates',
  banners: 'banners',
  manualShopOrder: 'manual-shop-order',
};

export {
  url,
  endpoint
}
