import { Models } from '@rematch/core';
import { qrCode } from './qr_code';
import { auth } from './auth';
import { top } from './top';

export interface RootModel extends Models<RootModel> {
  qrCode: typeof qrCode,
  auth: typeof auth,
  top: typeof top,
};

export const models: RootModel = {
  qrCode, auth, top,
};
