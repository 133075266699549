import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import { useParseUrl } from "../../hooks/useParseUrl";
import { RootState } from "../../models/store";

import "./index.scss";

const BackToQRreaderComponent = (props) => {
  const dispatch = useDispatch<any>();
  const manualOrder = new URLSearchParams(location.search).get("manualOrder");
  const isWebview = useSelector((state: RootState) => state.qrCode.isWebview);
  const completeOrderBackUrl = useParseUrl(
    manualOrder && isWebview ? "/manual-order" : "/"
  );

  const toQRcodeReader = () => {
    if (props.authError) {
      dispatch.auth.logout();
    } else {
      navigate(completeOrderBackUrl);
    }
  };

  return (
    <button
      style={props.style}
      className="btn-return-to-qrcode-reader"
      onClick={toQRcodeReader}
    >
      {manualOrder && isWebview ? "戻る" : "QRコード読込に戻る"}
    </button>
  );
};

export default BackToQRreaderComponent;
