import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { captureException } from '@sentry/gatsby';

import InputPassword from "../InputPassword";
import "./index.scss";
import { regexString } from "../../utils/stringHelper";
import { handleDisabled } from "../../utils/disabled";
import { error_msg, success_msg } from "../../core/const/ja";
import { authService } from "../../core/services/auth-service";
import Modal from "../Modal";
import { apiService } from "../../core/services/api/api-service";

const UpdatePassword = () => {
  const [formValue, setFormValue] = useState<any>({});
  const [showModal, setShowModal] = useState<any>({});
  const [invalid, setInvalid] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    let isDifferent;
    if (
      formValue.confirm_password &&
      formValue.new_password?.trim() !== formValue.confirm_password?.trim()
    ) {
      isDifferent = true;
    } else {
      isDifferent = false;
    }
    setInvalid((prev) => ({
      ...prev,
      different: isDifferent,
    }));
  }, [formValue.new_password, formValue.confirm_password]);
  const handleChange = (e) => {
    let value, isValid;
    if (e.target.value && regexString(e.target.value)) {
      value = e.target.value;
      isValid = false;
    } else {
      value = null;
      isValid = true;
    }
    setFormValue((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
    setInvalid((prev) => ({
      ...prev,
      [e.target.name]: isValid,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = {
      password: formValue.password.trim(),
      new_password: formValue.new_password.trim(),
    };
    setIsLoading(true);
    await authService
      .updatePassword(result)
      .then((res) => {
        if (res) {
          setShowModal({
            ...showModal,
            title: success_msg.update_password,
            message: "",
            isShow: true,
            status: true,
            callback: () => {
              dispatch.auth.logout();
            },
          });
        }
      })
      .catch((error) => {
        captureException(new Error(apiService.handleErrorMess(error) || error));
        if (error) {
          setShowModal({
            ...showModal,
            title: error_msg.update_password,
            isShow: true,
            message: apiService.handleErrorMess(error),
            status: false,
            error: error.response?.status === 401,
          });
        }
      });
    setIsLoading(false);
  };
  const showErrorConfirm = () => {
    let mess = null;
    if (invalid.confirm_password) {
      mess = error_msg.wrong_input;
    } else if (invalid.different) {
      mess = error_msg.different_password;
    }
    return mess;
  };
  return (
    <div className="login-container">
      <form onSubmit={(event) => handleSubmit(event)}>
        <div className="form-group row">
          <label className="col-12 col-md-4 required">現在のパスワード:</label>
          <div className="col-12 col-md-8">
            <InputPassword name="password" onChange={handleChange} />
          </div>
          {invalid.password && (
            <span className="invalid-message col-offset-md-8">
              {error_msg.wrong_input}
            </span>
          )}
        </div>
        <div className="form-group row">
          <label className="col-12 col-md-4 required">新しいパスワード:</label>
          <div className="col-12 col-md-8">
            <InputPassword name="new_password" onChange={handleChange} />
          </div>
          {invalid.new_password && (
            <span className="invalid-message col-offset-md-8">
              {error_msg.wrong_input}
            </span>
          )}
        </div>
        <div className="form-group row">
          <label className="col-12 col-md-4 required">
            新しいパスワード（確認）:
          </label>
          <div className="col-12 col-md-8">
            <InputPassword name="confirm_password" onChange={handleChange} />
          </div>
          {showErrorConfirm() && (
            <span className="invalid-message col-offset-md-8">
              {showErrorConfirm()}
            </span>
          )}
        </div>
        <div className="form-group row">
          <button
            className="btn btn-primary col-offset-md-8"
            disabled={handleDisabled(formValue, invalid, isLoading, 3)}
          >
            パスワード変更する
          </button>
        </div>
      </form>
      <Modal data={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default UpdatePassword;
