import { createModel } from '@rematch/core';
import { RootModel } from '.';
const isBrowser = typeof window !== "undefined"
export const auth = createModel<RootModel>()({
  state: {
    token: isBrowser ? JSON.parse(localStorage.getItem('token')) : null,
    info: isBrowser ? JSON.parse(localStorage.getItem('info')) : null
  },
  reducers: {
    login(state, payload) {
      const userInfo = { login_id: payload.login_id, shop_name: payload.shop_name }
      localStorage.setItem('token', JSON.stringify(payload.token));
      localStorage.setItem('info', JSON.stringify(userInfo));
      return { ...state, token: payload.token, info: userInfo };
    },
    logout(state) {
      localStorage.removeItem('token');
      localStorage.removeItem('info');
      return {
        ...state, token: null, info: null
      }
    },
  },
  effects: (_dispatch) => ({})
});
