import React from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import { useParseUrl } from "../../hooks/useParseUrl";
import { RootState } from "../../models/store";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const loginUrl = useParseUrl("/login");
  const token = useSelector((state: RootState) => state.auth.token);
  if (!token && location.pathname !== `/login`) {
    navigate(loginUrl);
    return null;
  }
  return <Component {...rest} />;
};

export default PrivateRoute;
