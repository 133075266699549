import React, { useState } from "react";
import "./index.scss";
const InputPassword = (props) => {
  const { name, onChange } = props;
  const [showPass, setShowPass] = useState(false);
  const handleChange = (e) => {
    onChange(e);
  };
  return (
    <span className="input-password">
      <input
        name={name}
        type={showPass ? "text" : "password"}
        className="form-control"
        onChange={(e) => handleChange(e)}
      />
      <span className="eye" onClick={() => setShowPass(!showPass)}>
        <img src={!showPass ? "/show.png" : "/hide.png"} alt="" />
      </span>
    </span>
  );
};

export default InputPassword;
