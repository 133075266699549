import { apiService, ENDPOINT } from './api/api-service';
class AuthService {
  login(data) {
    return apiService.post(ENDPOINT.login, data)
  }

  logout() {
    localStorage.removeItem('token');
  }

  updatePassword(data) {
    return apiService.post(ENDPOINT.updatePassword, data)
  }

  register(user) {
    return apiService.post(ENDPOINT.register, user).then(info => {
      this.updateTokenLocal(info);
    });
  }

  updateTokenLocal(info) {
    if (info.token) {
      localStorage.setItem('token', JSON.stringify(info.token));
    }
  }
}
const authService = new AuthService();
export {
  authService
};
