export const filterDataByCondition = (
  data,
  condition,
  array,
  isPickup = false
) => {
  if (condition[array[0]]) {
    const id = condition[array[0]].id;
    let filteredData = data?.filter((item) =>
      id === 0
        ? item
        : (item[array[0] + "Filters"] || []).filter((itemId) => itemId === id)
            .length > 0
    );
    if (array[0] === "color") {
      filteredData = sortByPriority(
        isPickup
          ? filteredData?.filter((item) => {
              const currentTime = new Date().getTime();
              const pickupDisplayFromTime =
                !!item.pickupDisplayFrom &&
                Date.parse(item.pickupDisplayFrom.replace(/-/g, "/"));
              const pickupDisplayToTime =
                !!item.pickupDisplayTo &&
                Date.parse(item.pickupDisplayTo.replace(/-/g, "/"));
              return (
                (!pickupDisplayFromTime && !pickupDisplayToTime) ||
                (!pickupDisplayFromTime && currentTime < pickupDisplayToTime) ||
                (!pickupDisplayToTime && currentTime > pickupDisplayFromTime) ||
                (currentTime > pickupDisplayFromTime &&
                  currentTime < pickupDisplayToTime)
              );
            })
          : filteredData,
        condition?.["categoryType"]?.id
      );
    }

    return filterDataByCondition(
      filteredData,
      condition,
      array.slice(1, array.length),
      isPickup
    );
  } else {
    return data;
  }
};

export const sortByPriority = (data: any[], keyMapped: string = "") =>
  data?.sort((item1, item2) => {
    let item1DisplayPriority =
      item1?.bannerOption?.displayPriority?.[keyMapped] || null;
    let item2DisplayPriority =
      item2?.bannerOption?.displayPriority?.[keyMapped] || null;

    if (!item1DisplayPriority && !item2DisplayPriority) {
      item1DisplayPriority = item1?.displayPriority;
      item2DisplayPriority = item2?.displayPriority;
    }

    return item2DisplayPriority === item1DisplayPriority
      ? item2.id - item1.id
      : item1DisplayPriority === null
      ? 1
      : item2DisplayPriority === null
      ? -1
      : item2DisplayPriority - item1DisplayPriority;
  });
