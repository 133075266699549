import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import querystring from "query-string";

import { RootState } from "../../models/store";
import "./index.scss";

const ModeDropDown = () => {
  const { search } = useLocation();
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch<any>();
  const isWebview = useSelector((state: RootState) => state.qrCode.isWebview);
  const isInTestMode = useSelector((state: RootState) => state.top.testmode);
  const dropMenu = useRef(null);
  const parsedQuery = querystring.parse(search, { parseBooleans: true });

  const handleToggle = () => {
    setIsShow(!isShow);
  };

  const switchTestMode = () => {
    const nextTestMode = !isInTestMode;
    navigate(`?testmode=${nextTestMode}&webview=${isWebview}`);
    handleToggle();
  };

  useEffect(() => {
    if (!isWebview) {
      dispatch.qrCode.setIsWebview({
        isWebview: !!parsedQuery.webview,
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", (event) => {
      if (dropMenu && !dropMenu.current?.contains(event.target)) {
        setIsShow(false);
      }
    });
  }, []);

  return isWebview ? (
    <div ref={dropMenu} className="dropdown-mode">
      <div className="dropdown-icon" onClick={handleToggle}>
        <img
          src={isInTestMode ? "/more_vert_white.png" : "/more_vert.png"}
          alt="three dots"
        />
      </div>
      <ul className={`dropdown-menu ${isShow ? "show" : ""}`}>
        <li className="dropdown-item" onClick={switchTestMode}>
          {isInTestMode ? "営業モードに切り替える" : "テストモードに切り替える"}
        </li>
      </ul>
    </div>
  ) : null;
};

export default ModeDropDown;
