import React, { useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import querystring from "query-string";
import { RootState } from "../../models/store";

import "./index.scss";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const parsedSearch = querystring.parse(search, { parseBooleans: true });
  const isInTestMode = useSelector((state: RootState) => !!state.top.testmode);
  const setTestmode = useCallback(
    (payload) => dispatch.top.setTestmode(payload),
    [dispatch]
  );

  useEffect(() => {
    setTestmode({
      testmode: !!parsedSearch.testmode,
    });
  }, [parsedSearch]);

  return useMemo(
    () => (
      <main className="main-container">
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, user-scalable=0"
          />
          <title>{isInTestMode ? "テストモード" : "営業モード"}</title>
          <link
            rel="icon"
            type="image/png"
            href={process.env.GATSBY_API_IMAGE + "/favicon/favicon-16x16.png"}
            sizes="16x16"
          />
          <link
            rel="icon"
            type="image/png"
            href={process.env.GATSBY_API_IMAGE + "/favicon/favicon-32x32.png"}
            sizes="32x32"
          />
          <link
            rel="shortcut icon"
            href={process.env.GATSBY_API_IMAGE + "/favicon/favicon.ico"}
          />
          <link
            rel="icon"
            type="image/x-icon"
            href={process.env.GATSBY_API_IMAGE + "/favicon/favicon.ico"}
          />
          <link
            rel="mask-icon"
            href={
              process.env.GATSBY_API_IMAGE + "/favicon/safari-pinned-tab.svg"
            }
            color="#5bbad5"
          />
        </Helmet>
        {children}
      </main>
    ),
    [children, isInTestMode]
  );
};

export default Layout;
