import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import { captureException } from "@sentry/gatsby";

import InputPassword from "../InputPassword";
import "./index.scss";
import { regexString } from "../../utils/stringHelper";
import { handleDisabled } from "../../utils/disabled";
import { error_msg } from "../../core/const/ja";
import { authService } from "../../core/services/auth-service";
import Modal from "../Modal";
import { RootState } from "../../models/store";
import { apiService } from "../../core/services/api/api-service";
import { useParseUrl } from "../../hooks/useParseUrl";

const LoginComponent = () => {
  const topUrl = useParseUrl("/");
  const [formValue, setFormValue] = useState<any>({});
  const [showModal, setShowModal] = useState<any>({});
  const [invalid, setInvalid] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const token = useSelector((state: RootState) => state.auth.token);

  const handleChange = (e) => {
    let value, isValid;
    if (e.target.value && regexString(e.target.value)) {
      value = e.target.value;
      isValid = false;
    } else {
      value = null;
      isValid = true;
    }
    setFormValue({
      ...formValue,
      [e.target.name]: value?.trim(),
    });
    setInvalid({
      ...invalid,
      [e.target.name]: isValid,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = formValue;
    delete result.errors;
    setIsLoading(true);
    await authService
      .login(result)
      .then((res) => {
        if (res) {
          dispatch.auth.login({
            token: res.data.token,
            login_id: formValue.login_id,
            shop_name: res.data.shop_name,
          });
        }
        navigate(topUrl);
      })
      .catch((error) => {
        captureException(new Error(apiService.handleErrorMess(error) || error));
        if (error) {
          setShowModal({
            ...showModal,
            title: error_msg.login,
            isShow: true,
            message: apiService.handleErrorMess(error),
            status: false,
          });
        }
      });
    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      navigate(topUrl);
    }
  }, [token]);

  if (!token) {
    return (
      <div className="login-container">
        <form onSubmit={(event) => handleSubmit(event)}>
          <div className="form-group row">
            <label className="col-12 col-md-4 required">ログインID:</label>
            <div className="col-12 col-md-8">
              <input
                type="text"
                name="login_id"
                className="form-control w-100"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {invalid.login_id && (
              <span className="invalid-message col-offset-md-8">
                {error_msg.wrong_input}
              </span>
            )}
          </div>
          <div className="form-group row">
            <label className="col-12 col-md-4 required">パスワード:</label>
            <div className="col-12 col-md-8">
              <InputPassword name="password" onChange={handleChange} />
            </div>
            {invalid.password && (
              <span className="invalid-message col-offset-md-8">
                {error_msg.wrong_input}
              </span>
            )}
          </div>
          <div className="form-group row">
            <button
              className="btn btn-primary col-offset-md-8"
              disabled={handleDisabled(formValue, invalid, isLoading, 2)}
            >
              ログイン
            </button>
          </div>
        </form>
        <Modal data={showModal} setShowModal={setShowModal} />
      </div>
    );
  } else {
    return null;
  }
};

export default LoginComponent;
