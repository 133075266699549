import { createModel } from '@rematch/core';
import { RootModel } from '.';

export const qrCode = createModel<RootModel>()({
  state: {
    data: null,
    totalPrice: 0,
    error: null,
    orderID: null,
    isWebview: false,
  },
  reducers: {
    setQrCode(state, payload) {
      let total = payload.drink_price || 0;
      total += payload.banner_price || 0;
      total += payload.design_template_price || 0;
      if (payload.options) {
        for (let i = 0; i < payload.options.length; i++) {
          const element = payload.options[i];
          total += element.option_price;
        }
      };
      return { ...state, data: payload, totalPrice: total };
    },
    setError(state, payload) {
      return {
        ...state, error: payload
      }
    },
    setId(state, payload) {
      return {...state, orderID: payload.receipt_no}
    },
    setIsWebview(state, payload) {
      return { ...state, isWebview: payload.isWebview }
    }
  },
  effects: (_dispatch) => ({})
});
