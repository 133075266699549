module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"sampleRate":1,"tracesSampleRate":0.05,"attachStacktrace":true,"debug":false,"dsn":"https://6318b65052f048bd93006b1ab7a47465@o1057824.ingest.sentry.io/6044863","initialScope":{"tags":{"system":"Shop App"}},"browserTracingOptions":{"tracingOrigins":["https://shop.tag-coffee-stand.jp"]},"release":"TAG-Shop-App_2023-08-01"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icons":[{"src":"https://image.tag-coffee-stand.jp/favicon/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"https://image.tag-coffee-stand.jp/favicon/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"https://image.tag-coffee-stand.jp/favicon/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"https://image.tag-coffee-stand.jp/favicon/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"https://image.tag-coffee-stand.jp/favicon/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"https://image.tag-coffee-stand.jp/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"https://image.tag-coffee-stand.jp/favicon/android-chrome-256x256.png","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
