import React from "react";
import { useDispatch } from "react-redux";

import "./index.scss";

const Modal = (props) => {
  const { data, setShowModal } = props;
  const dispatch = useDispatch<any>();

  const closeModal = () => {
    if (data.error) {
      dispatch.auth.logout();
    }
    setShowModal({
      ...data,
      isShow: false,
    });
    if (data.callback) {
      data.callback();
    }
  };

  if (data.isShow) {
    return (
      <div className="modal">
        <div className="modal-content">
          <div className="modal-body">
            <span className="modal-title">
              <img
                src={
                  data.status
                    ? data.status === "warning"
                      ? "/icon-WARNING@3x.png"
                      : "/icon-OK@3x.png"
                    : "/icon-NG@3x.png"
                }
                className="modal-icon"
              />
              <span>{data.title}</span>
            </span>
            <div className="modal-confirm-content">
              <p>{data.message}</p>
            </div>
            <div className="modal-confirm">
              <button className="btn btn-primary" onClick={closeModal}>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Modal;
