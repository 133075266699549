import { createModel } from "@rematch/core";
import { RootModel } from "./";

export const top = createModel<RootModel>()({
  state: {
    testmode: false,
  },
  reducers: {
    setTestmode(state, payload) {
      return {
        ...state, testmode: payload.testmode,
      };
    },
  },
  effects: (_dispatch) => ({}),
});
