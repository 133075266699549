import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import { captureException } from "@sentry/gatsby";
import querystring from "query-string";

import AcceptOrderHeader from "./AcceptOrderHeader";
import OrderDetailComponent from "./orderDetail";
import UserDropDown from "../UserDropDown";
import { apiService, ENDPOINT } from "../../core/services/api/api-service";
import { RootState } from "../../models/store";
import { useParseUrl } from "../../hooks/useParseUrl";

import "./index.scss";

const AcceptOrder = () => {
  const completeOrderUrl = useParseUrl("/complete-order");
  const acceptOrderErrorUrl = useParseUrl("/accept-order-error");
  const topUrl = useParseUrl("/");
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const { search } = useLocation();
  const parsedSearch = querystring.parse(search, { parseBooleans: true });
  const orderDetails = useSelector((state: RootState) => state.qrCode.data);
  const totalPrice = useSelector((state: RootState) => state.qrCode.totalPrice);
  const isInTestMode = useSelector((state: RootState) => !!state.top.testmode);

  const toCompleteOrder = async () => {
    setDisabled(true);
    const uri = `${ENDPOINT.shopOrder}`;
    const data = {
      drink_order_id: orderDetails.drink_order_id,
      is_draft: isInTestMode,
    };
    await apiService
      .post(uri, data)
      .then((res) => {
        if (res) {
          dispatch.qrCode.setId(res.data);
        }
        navigate(completeOrderUrl);
      })
      .catch((e) => {
        captureException(new Error(apiService.handleErrorMess(e) || e));
        dispatch.qrCode.setError({
          status: e.response?.status,
          message: apiService.handleErrorMess(e),
        });
        navigate(acceptOrderErrorUrl);
      });
  };

  const toQRcodeReader = () => {
    navigate(topUrl);
  };

  useEffect(() => {
    if (!orderDetails && !parsedSearch.hash) {
      navigate(topUrl);
    }
  });

  useEffect(() => {
    if (orderDetails !== null) {
      return;
    }
    const hash = parsedSearch.hash;
    const uri = `${ENDPOINT.preOrder}/${hash}`;
    apiService
      .get(uri)
      .then((res) => {
        if (res) {
          dispatch.qrCode.setQrCode(res.data);
        }
      })
      .catch((e) => {
        captureException(new Error(apiService.handleErrorMess(e) || e));
        dispatch.qrCode.setError({
          status: e.response?.status,
          message: apiService.handleErrorMess(e),
        });
        navigate(acceptOrderErrorUrl);
      });
  }, [parsedSearch]);

  return useMemo(
    () =>
      orderDetails ? (
        <>
          <UserDropDown excludeTestModeStyle />
          <AcceptOrderHeader excludeTestModeStyle title="注文内容の確認" />
          <OrderDetailComponent
            orderDetails={orderDetails}
            totalPrice={totalPrice}
          />
          <div className="btn-group-order">
            <button className="btn-accept btn-cancel" onClick={toQRcodeReader}>
              キャンセル
            </button>
            <button
              className={`btn-accept btn-accept-order ${
                disabled ? "loading loading-sm" : ""
              }`}
              onClick={toCompleteOrder}
              disabled={disabled}
            >
              注文受付
            </button>
          </div>
        </>
      ) : null,
    [orderDetails, disabled]
  );
};

export default AcceptOrder;
